import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import OCRApp from './OCRApp';
import TaskPage from './TaskPage';

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/fr" replace />} />
          <Route path="/:lang" element={<OCRApp />} />
          <Route path="/:lang/task/:uniqid" element={<TaskPage />} />
        </Routes>
      </Router>
    </GoogleOAuthProvider>
  );
}

export default App;
