import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';
import { Helmet } from 'react-helmet';

// Assurez-vous que ces variables d'environnement sont correctement définies
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION
});

const dynamodb = new AWS.DynamoDB.DocumentClient();
const s3 = new AWS.S3();

function OCRApp() {
  const navigate = useNavigate();
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const fileInputRef = useRef(null);
  const dragCounter = useRef(0);

  const [files, setFiles] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [uploadStatus, setUploadStatus] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [popoverMessage, setPopoverMessage] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const addEventToDynamoDB = async (filename) => {
    const uniqid = uuidv4();
    const params = {
      TableName: 'events',
      Item: {
        uniqid: uniqid,
        email: userEmail,
        filename: filename,
        status: 0,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString()
      }
    };

    try {
      await dynamodb.put(params).promise();
      console.log('Enregistrement ajouté à DynamoDB avec succès');
      return { success: true, uniqid: uniqid };
    } catch (error) {
      console.error('Erreur lors de l\'ajout à DynamoDB:', error);
      return { success: false, uniqid: null };
    }
  };

  const uploadToS3 = async (file) => {
    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `${userEmail}/${file.name}`,
      Body: file
    };

    try {
      await s3.upload(params).promise();
      return true;
    } catch (error) {
      console.error('Erreur lors de l\'upload sur S3:', error);
      return false;
    }
  };

  const handleGoogleSuccess = useCallback((response) => {
    const decoded = jwtDecode(response.credential);
    console.log('Connexion Google réussie:', decoded);
    setIsLoggedIn(true);
    setUserEmail(decoded.email);
  }, []);

  const handleGoogleFailure = useCallback((error) => {
    console.error('Échec de la connexion Google:', error);
    setPopoverMessage(t('googleLoginError'));
    setShowPopover(true);
  }, [t]);

  const handleLaunchOCR = useCallback(async () => {
    if (!isLoggedIn || files.length === 0) {
      setPopoverMessage(t('alertMessage'));
      setShowPopover(true);
      return;
    }

    setUploadStatus('uploading');

    for (const file of files) {
      if (file.type !== 'application/pdf') {
        setUploadStatus('error');
        setPopoverMessage(t('pdfOnlyError'));
        setShowPopover(true);
        return;
      }

      const uploadSuccess = await uploadToS3(file);
      if (!uploadSuccess) {
        setUploadStatus('error');
        setPopoverMessage(t('uploadError'));
        setShowPopover(true);
        return;
      }

      const { success, uniqid } = await addEventToDynamoDB(file.name);
      if (!success) {
        setUploadStatus('error');
        setPopoverMessage(t('dbError'));
        setShowPopover(true);
        return;
      }

      console.log(`Fichier uploadé avec succès. ID unique : ${uniqid}`);
      navigate(`/${lang}/task/${uniqid}`);
      return; // Sortir de la boucle après le premier fichier réussi
    }

    setUploadStatus('success');
    setPopoverMessage(t('uploadSuccess'));
    setShowPopover(true);
  }, [isLoggedIn, files, userEmail, t, navigate, lang]);

  const handleDrag = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragIn = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    dragCounter.current = 0;
    
    const droppedFiles = Array.from(e.dataTransfer.files).filter(
      file => file.type === 'application/pdf'
    );
    
    if (droppedFiles.length > 0) {
      setFiles(droppedFiles);
    } else {
      setPopoverMessage(t('pdfOnlyError'));
      setShowPopover(true);
    }
  }, [t]);

  useEffect(() => {
    window.addEventListener('dragenter', handleDragIn);
    window.addEventListener('dragleave', handleDragOut);
    window.addEventListener('dragover', handleDrag);
    window.addEventListener('drop', handleDrop);

    return () => {
      window.removeEventListener('dragenter', handleDragIn);
      window.removeEventListener('dragleave', handleDragOut);
      window.removeEventListener('dragover', handleDrag);
      window.removeEventListener('drop', handleDrop);
    };
  }, [handleDragIn, handleDragOut, handleDrag, handleDrop]);

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files).filter(
      file => file.type === 'application/pdf'
    );
    
    if (uploadedFiles.length > 0) {
      setFiles(uploadedFiles);
    } else {
      setPopoverMessage(t('pdfOnlyError'));
      setShowPopover(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('metaTitle')}</title>
        <meta name="description" content={t('metaDescription')} />
      </Helmet>
      <div 
        className="min-h-screen bg-gradient-to-r from-primary to-secondary flex flex-col items-center justify-center relative"
        onDragEnter={handleDragIn}
        onDragLeave={handleDragOut}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {isDragging && (
          <div className="absolute inset-0 bg-blue-500 bg-opacity-50 flex items-center justify-center z-50">
            <p className="text-white text-2xl font-bold">{t('dropFilesHere')}</p>
          </div>
        )}
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full relative z-10">
          <h1 className="text-3xl font-bold mb-6 text-center text-tertiary">{t('title')}</h1>
          
          <button
            onClick={() => fileInputRef.current.click()}
            className="w-full py-2 px-4 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition duration-300"
          >
            {t('selectFiles')}
          </button>
          <input
            ref={fileInputRef}
            type="file"
            accept=".pdf"
            multiple
            onChange={handleFileUpload}
            className="hidden"
          />
          
          {files.length > 0 && (
            <div className="mt-4">
              <p>{files.length} {t('filesSelected')}</p>
              <ul className="list-disc pl-5">
                {files.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
            </div>
          )}
          
          {!isLoggedIn && (
            <div className="mt-4">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleFailure}
                useOneTap
              />
            </div>
          )}
          
          <button
            onClick={handleLaunchOCR}
            disabled={!isLoggedIn || files.length === 0 || uploadStatus === 'uploading'}
            className={`w-full py-2 rounded transition duration-300 mt-4 ${
              !isLoggedIn || files.length === 0 || uploadStatus === 'uploading'
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'bg-accent text-white hover:bg-tertiary'
            }`}
          >
            {uploadStatus === 'uploading' ? t('uploading') : t('launchOCR')}
          </button>
        </div>
        
        {showPopover && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg">
              <p>{popoverMessage}</p>
              <button onClick={() => setShowPopover(false)} className="mt-2 bg-accent text-white py-1 px-2 rounded">
                {t('close')}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default OCRApp;
