import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import AWS from 'aws-sdk';

const dynamodb = new AWS.DynamoDB.DocumentClient();
const s3 = new AWS.S3();

function TaskPage() {
  const { uniqid, lang } = useParams();
  const [status, setStatus] = useState(0);
  const [email, setEmail] = useState('');
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  useEffect(() => {
    const checkStatus = async () => {
      const params = {
        TableName: 'events',
        Key: { uniqid: uniqid }
      };

      try {
        const result = await dynamodb.get(params).promise();
        if (result.Item) {
          setStatus(result.Item.status);
          setEmail(result.Item.email);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du statut:', error);
      }
    };

    const intervalId = setInterval(checkStatus, 1000);

    return () => clearInterval(intervalId);
  }, [uniqid]);

  const getStatusMessage = () => {
    switch (status) {
      case 0:
        return t('statusPending');
      case 1:
        return t('statusProcessing');
      case 2:
        return t('statusCompleted');
      default:
        return t('statusUnknown');
    }
  };

  const handleDownload = async () => {
    if (status !== 2) return;

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      Key: `out/${email}/${uniqid}.pdf`,
      Expires: 60 // URL valide pendant 60 secondes
    };

    try {
      const url = await s3.getSignedUrlPromise('getObject', params);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Erreur lors de la génération de l\'URL de téléchargement:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('taskMetaTitle', { status: getStatusMessage() })}</title>
        <meta name="description" content={t('taskMetaDescription', { status: getStatusMessage() })} />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-r from-primary to-secondary flex flex-col items-center justify-center">
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
          <h1 className="text-3xl font-bold mb-6 text-center text-tertiary">{t('taskStatus')}</h1>
          <p className="text-lg mb-4">{getStatusMessage()}</p>
          {status === 2 && (
            <button
              onClick={handleDownload}
              className="w-full py-2 px-4 bg-accent text-white rounded hover:bg-tertiary transition duration-300"
            >
              {t('downloadFile')}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default TaskPage;
